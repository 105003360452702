<template>
  <div>
    <validation-observer
      ref="posInputForm"
      tag="form"
      @submit.prevent="submitForm()">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-text-field
            v-model="formData.serialNumber"
            outlined
            :rules="formRules.serialNumber"
            label="Serial number" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-text-field
            v-model="formData.assetId"
            outlined
            :rules="formRules.assetId"
            label="Asset ID" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-text-field
            v-model="formData.brandModel"
            outlined
            :rules="formRules.brandModel"
            label="Brand/Model" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-select
            v-model="formData.status"
            :items="statusList"
            label="Status"
            outlined
            :rules="formRules.status" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-text-field
            v-model="formData.posId"
            outlined
            :rules="formRules.posId"
            label="POS ID" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-date-picker
            v-model="formData.registeredDate"
            label="Registered Date"
            outlined
            :rules="formRules.registeredDate" />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-select
            v-model="formData.warehouse"
            :items="warehouse"
            label="Warehouse"
            item-text="name"
            return-object
            outlined
            :rules="formRules.warehouse" />
        </v-col>
        <v-col
          v-if="method === 'create'"
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <gw-textarea
            v-model="formData.note"
            outlined
            :rules="formRules.note"
            label="Note" />
        </v-col>
        <v-col cols="12">
          <v-btn
            class="mr-4"
            :disabled="loading"
            color="secondary"
            outlined
            @click="onCancel">
            CANCEL
          </v-btn>
          <v-btn
            :disabled="loading"
            color="secondary"
            type="submit">
            SUBMIT
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
export default {
    props: {
        value: {
          type: Object,
          default: null
        },
        method: {
            type: String,
            default: 'create'
        },
        statusList: {
            type: Array,
            default: () => []
        },
        warehouse: {
          type: Array,
          default: () => []
        },
        loading: {
          type: Boolean,
          default: false
        }
    },
    data () {
        return {
            formData: {
                serialNumber: '',
                posId: '',
                brandModel: '',
                registeredDate: null,
                status: '',
                warehouse: null,
                note: '',
                assetId: ''
            },
            formRules: {
                serialNumber: { required: true },
                posId: { required: false },
                brandModel: { required: true },
                registeredDate: { required: false },
                status: { required: true },
                warehouse: { required: true },
                note: { required: false },
                assetId: { required: true }
            }
        }
    },
    mounted () {
      if (this.method === 'edit' && this.value) {
        this.formData = {
          serialNumber: this.value.serialNumber || '',
          posId: this.value.posId || '',
          brandModel: this.value.brandModel || '',
          registeredDate: this.value.registeredDate || null,
          status: this.value.status || '',
          warehouse: this.value.warehouse || null,
          note: this.value.note || '',
          assetId: this.value.assetId || ''
        }
      }
    },
    methods: {
        async submitForm () {
            const valid = await this.$refs.posInputForm.validate()
            if (valid) {
                this.$emit('onSubmit', this.formData)
            }
        },
        async onCancel () {
          this.$emit('onCancel')
        }
    }
}
</script>

<style scoped>
</style>
